import React, { useEffect } from 'react'

import CustomForm from '../../CustomForm/CustomForm'

import { BlockAppointmentFormSection } from './BlockAppointmentForm.styled'

import { appointmentFormStructure } from './BlockAppointmentFormStructure'
import { CarStateContext } from '../../../layouts/PageLayout/PageLayout'

const BlockAppointmentForm = data => {
	return (
		<CarStateContext.Consumer>
			{context => {
				return (
					<BlockAppointmentFormSection paddingBottom={data.paddingBottom} paddingTop={data.paddingTop}>
						<CustomForm location={context.location} formData={appointmentFormStructure} />
					</BlockAppointmentFormSection>
				)
			}}
		</CarStateContext.Consumer>
	)
}

export default BlockAppointmentForm
