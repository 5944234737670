exports.appointmentFormStructure = [
	{
		type: 'select',
		name: 'car-info',
		basetype: 'select',
		content: '',
		labels: ['Informatie over uw voertuig'],
		options: ['default:1', 'placeholder', 'width-49', 'title']
	},
	{
		type: 'select*',
		name: 'stage',
		basetype: 'select',
		content: '',
		labels: ['Stage 1'],
		options: ['placeholder', 'width-49', 'locked', 'single']
	},
	{
		type: 'select*',
		name: 'brand',
		basetype: 'select',
		content: '',
		labels: ['Automerk'],
		options: ['placeholder', 'width-49', 'locked']
	},
	{
		type: 'select*',
		name: 'model',
		basetype: 'select',
		content: '',
		labels: ['Model'],
		options: ['placeholder', 'width-49', 'locked']
	},
	{
		type: 'select*',
		name: 'generation',
		basetype: 'select',
		content: '',
		labels: ['Generatie'],
		options: ['placeholder', 'width-49', 'locked']
	},
	{
		type: 'select*',
		name: 'type',
		basetype: 'select',
		content: '',
		labels: ['Motortype'],
		options: ['placeholder', 'width-49', 'locked']
	},
	{
		type: 'select*',
		name: 'transmission',
		basetype: 'select',
		content: '',
		labels: ['Handgeschakeld', 'Automaat'],
		options: ['placeholder', 'width-49', 'locked', 'single']
	},

	{
		type: 'select',
		name: 'personal-info',
		basetype: 'select',
		content: '',
		labels: ['Informatie over uw afspraak'],
		options: ['default:1', 'placeholder', 'width-49', 'title']
	},
	{
		type: 'text*',
		name: 'your-name',
		basetype: 'text',
		content: '',
		labels: ['Uw voornaam'],
		options: ['placeholder', 'width-49']
	},
	{
		type: 'text*',
		name: 'your-last-name',
		basetype: 'text',
		content: '',
		labels: ['Uw achternaam'],
		options: ['placeholder', 'width-49']
	},
	{
		type: 'email*',
		name: 'your-email',
		basetype: 'email',
		content: '',
		labels: ['Uw email adres'],
		options: ['width-49', 'placeholder']
	},
	{
		type: 'text*',
		name: 'your-phone',
		basetype: 'text',
		content: '',
		labels: ['Uw telefoonnummer'],
		options: ['placeholder', 'width-49']
	},
	{
		type: 'date*',
		name: 'datum',
		basetype: 'date',
		content: '',
		labels: ['Uw datum'],
		options: ['placeholder', 'width-49', 'custom']
	},
	{
		type: 'select*',
		name: 'tijdstip',
		basetype: 'selectTime',
		content: '',
		labels: ['Uw tijdstip'],
		options: ['placeholder', 'width-49', 'custom']
	},
	{
		type: 'textarea',
		name: 'your-message',
		basetype: 'textarea',
		content: '',
		labels: ['Uw opmerkingen of vraag hier'],
		options: ['placeholder', 'width-100']
	},
	{
		type: 'submit',
		name: '',
		basetype: 'submitCalendar',
		content: '',
		labels: ['Maak afspraak!'],
		options: []
	}
]
